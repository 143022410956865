import React, { FunctionComponent, useEffect, useState, useRef } from 'react'
import { graphql } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import {
  MapComponent,
  StringToLatLng,
  tracksData,
} from '../components/MapComponent'
import GpxParser, { Track } from 'gpxparser'

export interface mapTemplateProps {
  data: {
    allWpPage: {
      edges: {
        node: {
          id: string
          title: string
          databaseId: number
          gPS: string
          bikeRide: string[]
          slug: string
          dateArrived: string
          dateLeft: string
          featuredImage: {
            node: {
              localFile: {
                childImageSharp: {
                  gatsbyImageData: IGatsbyImageData
                }
              }
            }
          }
        }
      }[]
    }
    allWpMediaItem: {
      nodes: tracksData[]
    }
  }
  pageContext: {
    ids: number[]
    trackId: number
    towns: town[]
    gps: string
    zoom: number
    single: boolean
  }
}

export interface town {
  id: number
  countryLink: string
  slug: string
  gps: string
  title: string
}

const MapTemplate: FunctionComponent<mapTemplateProps> = (props) => {
  const { data } = props
  const gps = StringToLatLng(props.pageContext.gps)
  const towns = data.allWpPage.edges.map((el) => ({
    ...el,
    countryLink:
      props.pageContext.towns.find(
        (element) => element.id === el.node.databaseId
      )?.countryLink ?? '',
  }))
  return (
    <Layout>
      <MapComponent
        data={towns}
        zoom={props.pageContext.zoom}
        center={gps}
        single={props.pageContext.single}
        tracksData={data.allWpMediaItem.nodes}
      />
    </Layout>
  )
}

export default MapTemplate

export const query = graphql`
  query($ids: [Int], $trackId: Int) {
    allWpPage(filter: { databaseId: { in: $ids } }) {
      edges {
        node {
          id
          title
          databaseId
          gPS
          slug
          dateArrived
          dateLeft
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 450, height: 450, layout: FIXED)
                }
              }
            }
          }
        }
      }
    }
    allWpMediaItem(
      filter: {
        localFile: { extension: { eq: "gpx" } }
        parentDatabaseId: { eq: $trackId }
      }
    ) {
      nodes {
        localFile {
          extension
          absolutePath
        }
        mediaItemUrl
      }
    }
  }
`

MapTemplate.defaultProps = {}
